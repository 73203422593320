$(document).ready(function() {
	$("#prdSeriesSearch").on("keyup", function() {
    var value = $(this).val().toLowerCase();
    $("#prdSeriesSearchList li").filter(function() {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
    });
  });
	$("#prdSeriesSearchRadio label").on('click', function () {
		$("#tagRadio").next('.prd__series-radio-tag').hide();
		var val = $(this).text();
		$("#tagRadio").after('<a href="#" class="prd__series-radio-tag"><span>' + val + '</span> <img src="img/ic-close.svg" alt=""></a>');
		$(".prd__series-radio-tag").on('click', function(){
			$(this).remove();
		});
	});

});